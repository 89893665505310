"use client";

import { Next13ProgressBar } from "@/progressbar";
import { getImgProps } from "@/utils";
import { HistoryInterceptorProvider, NextPropsProvider } from "@palette.tools/react";
import React, { Suspense, useEffect } from "react";
import "@/polyfills/array";
import { initAnalytics } from "@palette.tools/model.client";


export default function LayoutClient({ children} : { children: React.ReactNode }) {

  useEffect(() => {
    initAnalytics();
  }, []);

  return <NextPropsProvider getImgProps={getImgProps}>
    <HistoryInterceptorProvider>
      {children}
    </HistoryInterceptorProvider>
    <Suspense>
      <Next13ProgressBar
        height="4px"
        color="#C860E3"
        delay={1000}
        showOnShallow
        options={{
          showSpinner: true
        }}
      />
    </Suspense>
  </NextPropsProvider>
}
